import './App.css';

function App() {
    return (
        <div id="page">
            <div id="page-fss" className="section-overlay" data-ambient-color="#570087" data-diffuse-color="#e000ff"></div>
            <div className="section-overlay bg-black overlay-opacity-2"></div>

            <div className="container-fluid">
                <div className="row">
                    <div id="info" className="col-md-12 text-white text-center page-info col-transform">
                        <div className="vert-middle">
                            <div className="reveal scale-out">

                                <div className="p-t-b-15">
                                    <img src="images/rentsafe-min.png" height="40" alt="" />
                                </div>

                                <div className="p-t-b-15">
                                    <h2><span className="font-weight-200">We're launching our</span><br />DIY Property Management System<br /><span className="font-weight-200">Soon</span></h2>

                                    <p>In the meantime,<br />you can login to the console using the link below<br />
                                    </p>
                                </div>
                                <div className="p-t-b-20">
                                    <i className="icon icon-sm icon-arrows-slim-down-dashed"></i>
                                </div>

                                <div className="p-t-b-30">
                                    <p>
                                        <a href="https://console.rentsafe.app" className="link-white"><i
                                            className="fa fa-hotel"></i> Console Login</a>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default App;
